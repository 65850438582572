import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { isElement } from "react-dom/test-utils";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const SliderBrand = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [uniqueBrands, setUniqueBrands] = useState([]);

  useEffect(() => {
    if (props.category) {
      const allBrand = async () => {
        const url = `${Baseurl}/api/v1/brand/category-brand/${props.category}`;
        const resp = await axios.get(url);
        if (resp.data.success) {
          setUniqueBrands(resp.data.brands);
        }
      };
      allBrand();
    }
  }, [props.category]);

  return (
    <>
      {uniqueBrands.length > 0 ? (
        <>
          <div className="offernyka">
            <div className="nykacard ">
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#315999",
                  fontFamily: "Edu VIC WA NT Beginner,cursive",
                }}
              >
                Other Brands
              </div>
              <div className="" style={{ margin: "10px" }}>
                <Swiper
                  slidesPerView={9}
                  // spaceBetween={3}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 5,
                      // spaceBetween: 5,
                      // spaceBetween:10,
                      spaceBetween: 14,
                    },
                    "@0.75": {
                      slidesPerView: 5,
                      spaceBetween: 15,
                    },
                    "@1.00": {
                      slidesPerView: 9,
                      spaceBetween: 10,
                    },
                    "@1.50": {
                      slidesPerView: 9,
                      spaceBetween: 13,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  style={{ paddingBottom: "5px" }}
                >
                  {uniqueBrands.map((indBrand, index) => (
                    <SwiperSlide
                      key={index}
                      className="forsliderSwiper"
                      onClick={() =>
                        navigate(`/${props.category}/${indBrand.name}`)
                      }
                    >
                      <div
                        className="brandNames"
                        style={{
                          width:
                            indBrand.name === params.brand ? "8rem" : "7rem",
                          height:
                            indBrand.name === params.brand ? "8rem" : "7rem",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #F8DE22",
                          backgroundPosition: "center",
                          color: "#000",
                          backgroundSize: "100%",
                          // fontWeight: "bold",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          overflow: "hidden",
                          backgroundImage: `url(${indBrand.mobileImage})`,
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <mark
                          className="marktag"
                          style={{
                            backgroundColor: "yellow",
                            //   paddingTop: "5px",
                            marginTop: "2rem",
                            borderRadius: "3px",
                            // position:"absolute"
                          }}
                        >
                          {indBrand.name.length > 8
                            ? `${indBrand.name.slice(0, 8)}...`
                            : indBrand.name}
                        </mark>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SliderBrand;
