import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";
import { Affix, Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";
import { MdOutlineCancel } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { BiSort } from "react-icons/bi";
import { getCartTotal } from "../../redux/cart/CartSlice";
import { signout } from "../../redux/athentication/Athentication";
import { AiOutlineSortDescending } from "react-icons/ai";
import { AiOutlineSortAscending } from "react-icons/ai";
import { GoSortAsc } from "react-icons/go";
import Offcanvas from "react-bootstrap/Offcanvas";
import { PiSortAscendingLight } from "react-icons/pi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoProductFound from "../../component/NoProductFound";
import { FaSearch } from "react-icons/fa";
import { set_checkout_authentication_status } from "../../redux/athentication/Athentication";
import SliderBrand from "./SliderBrand";

const BrandRelatedProduct = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef();
  const ref = useRef();

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);
  const { isAuth } = useSelector((state) => state.Athentication);
  const { categoryShow } = useSelector((store) => store.category);
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [ProductSearchResult, setProductSearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const [minSlideValue, setMinSlideValue] = useState(0);
  const [maxSlideValue, setMaxSlideValue] = useState(1000);
  const [sortPrice, setSortPrice] = useState([]);
  const [sortAlphabeticalOrder, setSortAlphabeticalOrder] = useState([]);
  const [selectedValue, setSelectedValue] = useState([
    minSlideValue,
    maxSlideValue,
  ]);
  const [current_category, setcurrent_category] = useState("");
  const [selected_category_tag, setselected_category_tag] = useState([]);
  const [category_tags, setcategory_tags] = useState([]);
  const [category_products, setcategory_products] = useState([]);
  const [tag_loading, settag_Loading] = useState(true);
  const [product_loading, setproduct_Loading] = useState(true);
  const [filtered_products, setfiltered_products] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const [gotLimitProduct, setGotLimitProduct] = useState(false);
  const [gotIncreasedLimitProduct, setGotIncreasedLimitProduct] =
    useState(false);

  const [discount_Percentage, setDiscount_Percentage] = useState("10");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [filterShow, setFilterShow] = useState(false);

  const handleFilterClose = () => setFilterShow(false);
  const handleFilterShow = () => setFilterShow(true);

  const [broughtProducts, setBroughtProducts] = useState([]);
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [productByBrand, setProductByBrand] = useState([]);
  const [selected_brand, setSelected_brand] = useState([]);

  useEffect(() => {
    if (params.brand) {
      let filterBrand = [];
      filterBrand.push(params.brand);
      setSelected_brand(filterBrand);
    }
  }, [params]);

  useEffect(() => {
    if (params.category) {
      const getProductByBrand = async () => {
        const url = `${Baseurl}/api/v1/product/all-products-by-category-for-brand/${params.category}`;
        const resp = await axios.get(url);
        if (resp.data.success) {
          setBroughtProducts(resp.data.products);
        }
      };
      getProductByBrand();
    }
  }, [params]);
  useEffect(() => {
    if (broughtProducts.length > 0 && selected_brand.length > 0) {
      const ToBeFilterProducts = broughtProducts.filter((indProducts) =>
        selected_brand.includes(indProducts.brand)
      );
      if (ToBeFilterProducts) {
        console.log(ToBeFilterProducts, "ToBeFilterProducts");
        setfiltered_products(ToBeFilterProducts);
        setProductByBrand(ToBeFilterProducts);
        setproduct_Loading(false);
      }
    }
  }, [selected_brand, broughtProducts]);
  useEffect(() => {
    if (broughtProducts.length > 0) {
      const product_data = broughtProducts;
      const brands = product_data.map((product) => product.brand);
      const uniqueBrandsSet = new Set(brands);
      const uniqueBrandsArray = Array.from(uniqueBrandsSet);
      setUniqueBrands(uniqueBrandsArray);
    }
  }, [broughtProducts]);
  const onChange = async (value) => {
    setSelectedValue(value);
  };

  const handleFilter = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      // setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchchange();
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
      setSearch("");
    } else {
      alert("Please type something...");
    }
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowFilterMenu(false);
    }
  };

  useEffect(() => {
    if (showFilterMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterMenu]);

  useEffect(() => {
    if (filterName === "Alphabetically, A-Z") {
      const sortedProducts = filtered_products.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      setfiltered_products(sortedProducts);
    } else if (filterName === "Alphabetically, Z-A") {
      const products = filtered_products.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setfiltered_products(products);
    }
  }, [filterName]);

  useEffect(() => {
    const getProducts = category_products;
    const slideFilterProduct = getProducts.filter(
      (product) =>
        product.packSize[0].PackSizeOptions[0].Price >= selectedValue[0] &&
        product.packSize[0].PackSizeOptions[0].Price <= selectedValue[1]
    );
    setfiltered_products(slideFilterProduct);
  }, [selectedValue]);

  useEffect(() => {
    const prices = filtered_products.map(
      (product) => product.packSize[0].PackSizeOptions[0].Price
    );
    const lowestPrice = Math.min(...prices);
    setMinSlideValue(lowestPrice);
    const highestPrice = Math.max(...prices);
    setMaxSlideValue(highestPrice);
  }, []);

  const selectFilterChange = (value) => {
    setSortAlphabeticalOrder(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortAlphabeticalOrder === "title-descending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      setfiltered_products(sortedProducts);
    } else if (sortAlphabeticalOrder === "title-ascending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortAlphabeticalOrder]);
  const handletagPress = (e, value) => {
    let updatedSelectedBrand;

    if (selected_brand.includes(value)) {
      // Remove the brand from the array
      updatedSelectedBrand = selected_brand.filter((tag) => tag !== value);
    } else {
      // Add the brand to the array
      updatedSelectedBrand = [...selected_brand, value];
    }

    setSelected_brand(updatedSelectedBrand);

    // Check if the updated array is empty
    if (updatedSelectedBrand.length === 0) {
      console.log("empty");
      setfiltered_products(broughtProducts);
      setProductByBrand(broughtProducts);
    }
  };

  const selectPriceChange = (value) => {
    setSortPrice(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortPrice === "High to Low") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          b.packSize[0].PackSizeOptions[0].Price -
          a.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    } else if (sortPrice === "Low to High") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          a.packSize[0].PackSizeOptions[0].Price -
          b.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortPrice]);
  const catSearchClick = (category) => {
    if (search !== "") {
      navigate(`/search/${category.superCategory}/${category.slugUrl}`);
      setShowResults(false);
      setSearch("");
    }
  };
  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };
  console.log(params.category, "params.category");

  return (
    <>
      <div>
        <div className="topbar-slider clearfix category-mob">
          <div className="container-fluid"></div>
        </div>

        <Affix offsetTop={0}></Affix>
        <div className="desktopHeader" style={{ backgroundColor: "#2F415D" }}>
          <div className="leftHeaderDesktop">
            <div>
              <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/logonew14.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                </Link>
              </div>
            </div>
            {mainHomeSliderTotal &&
              mainHomeSliderTotal.map((indCat) => (
                <Link to={`/category/${indCat.slugUrl}`}>
                  <h6 className="categorynametext">
                    {indCat.name.toUpperCase()}
                  </h6>
                </Link>
              ))}
          </div>
          <div className="rightHeaderDesktop">
            <div
              className="inputDivisionDesktop"
              onSubmit={(e) => searchsubmit(e)}
            >
              <span>
                <CiSearch
                  style={{ fontSize: "1.5rem" }}
                  type="submit"
                  onClick={serchclick}
                />
              </span>
              <input
                placeholder="search for products...."
                type="search"
                value={search}
                onChange={searchchange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyDown}
              />
            </div>
            {showResults && categorySearchResult.length > 0 && (
              <>
                <div className="serchsugist">
                  {categorySearchResult.length > 0 && (
                    <ul className="">
                      {categorySearchResult &&
                        categorySearchResult.map((category, index) => (
                          <li className="item" key={index}>
                            <div
                              className="mini-list-item d-flex align-items-center w-100 clearfix"
                              onClick={() => catSearchClick(category)}
                            >
                              <div className="mini-image text-center">
                                <Link className="item-link">
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category.desktopImage}
                                    src={category.desktopImage}
                                    alt={category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link className="item-title">
                                    {category.name}
                                  </Link>
                                </div>

                                <Link>
                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {category.superCategory}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {subcatSearchResult.length > 0 && (
                    <ul>
                      {subcatSearchResult &&
                        subcatSearchResult.map((sub_category, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={sub_category.desktopImage}
                                    src={sub_category.desktopImage}
                                    alt={sub_category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {sub_category.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {sub_category.superCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {categoryTagSearchResult.length > 0 && (
                    <ul>
                      {categoryTagSearchResult &&
                        categoryTagSearchResult.map((category_tag, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/tag-search/${category_tag.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category_tag.desktopImage}
                                    src={category_tag.desktopImage}
                                    alt={category_tag.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/tag-search/${category_tag.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {category_tag.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {category_tag.SubCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </>
            )}

            <div className="accountpiecesDesk account-parent iconset">
              <div className="account-link" title="Account">
                <i className="hdr-icon icon anm anm-user-al" />
              </div>
              <div id="accountBox">
                <div className="customer-links">
                  <ul className="m-0">
                    {isAuth === true ? (
                      <>
                        <li>
                          <Link to="/my-account">
                            <i className="icon anm anm-user-cil" />
                            My Account
                          </Link>
                        </li>
                        <li onClick={() => signoutClick()}>
                          <i className="icon anm anm-sign-out-al" />
                          Sign out
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          onClick={() => {
                            dispatch(set_checkout_authentication_status(0));
                            navigate("/login");
                          }}
                        >
                          <Link to="/login">
                            <i className="icon anm anm-user-cil" />
                            Sign In
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div
                className="accountpiecesDesk header-cart iconset"
                title="Cart"
              >
                <Link
                  className="header-cart btn-minicart clr-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#minicart-drawer"
                >
                  <i className="hdr-icon icon anm anm-cart-l" />
                  <span className="cart-count">{CartItems.length}</span>
                </Link>

                <div
                  id="minicart-drawer"
                  className="minicart-right-drawer offcanvas offcanvas-end"
                  tabIndex={-1}
                >
                  {CartItems.length === 0 ? (
                    <>
                      <div
                        id="cartEmpty"
                        className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                      >
                        <div className="minicart-header d-flex-center justify-content-between w-100">
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                        </div>
                        <div className="cartEmpty-content mt-4">
                          <i className="icon anm anm-cart-l fs-1 text-muted" />
                          <p className="my-3">No Products in the Cart</p>
                          <span
                            className="btn btn-primary cart-btn"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => navigate("/")}
                          >
                            Continue shopping
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="cart-drawer" className="block block-cart">
                        <div className="minicart-header">
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                        </div>
                        <div className="minicart-content">
                          <ul className="m-0 clearfix">
                            {CartItems &&
                              CartItems.map((item, index) => (
                                <li
                                  className="item d-flex justify-content-center align-items-center"
                                  key={index}
                                >
                                  <span className="product-image rounded-3">
                                    <img
                                      className="blur-up lazyload"
                                      data-src={item.thumbnail}
                                      src={item.thumbnail}
                                      alt="product"
                                      title="Product"
                                      width={120}
                                      height={170}
                                    />
                                  </span>
                                  <div className="product-details">
                                    <span
                                      className="product-title limited-paragraph"
                                      onClick={() => {
                                        if ("slugurl" in item) {
                                          navigate(
                                            `/product-info/${item.slugurl}`
                                          );
                                        }
                                      }}
                                      data-bs-dismiss="offcanvas"
                                      aria-label="Close"
                                    >
                                      {item.ProductName}
                                    </span>
                                    <div className="variant-cart my-2 limited-paragraph">
                                      {item.packSizeOptionName} /{" "}
                                      {item.packSizeName}
                                    </div>
                                    <div className="priceRow">
                                      <div className="product-price">
                                        <span className="price">
                                          {" "}
                                          ₹{item.Price}
                                        </span>{" "}
                                        x{" "}
                                        <span className="price">
                                          {" "}
                                          {item.cart_Quentity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="minicart-bottom">
                          <div className="shipinfo">
                            {amountToGetfeeDelivery !== 0 && (
                              <div className="progress mb-2">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-valuenow={70}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  style={{
                                    width: `${amountToGetfeeDeliveryPercentage}%`,
                                  }}
                                >
                                  {amountToGetfeeDeliveryPercentage}%
                                </div>
                              </div>
                            )}

                            {amountToGetfeeDeliveryPercentage === 0 ? (
                              <>
                                <div className="freeShipMsg">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Only{" "}
                                  <span
                                    className="money"
                                    data-currency-usd=" ₹199.00"
                                    data-currency="USD"
                                  >
                                    {" "}
                                    ₹{amountToGetfeeDelivery}
                                  </span>{" "}
                                  away from
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="freeShipMsg d-block">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Congrats! You are eligible for
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="subtotal clearfix my-3">
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Total:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalPrice}
                              </span>
                            </div>
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Handlling Fee:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{HandllingFee}
                              </span>
                            </div>
                            <div className="totalInfo clearfix">
                              <span>Netpayable:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalAmount}
                              </span>
                            </div>
                          </div>

                          <div className="minicart-action d-flex mt-3">
                            <span
                              onClick={() => navigate("/check-out")}
                              className="proceed-to-checkout btn btn-primary w-50 me-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Check Out
                            </span>
                            <span
                              onClick={() => navigate("/cart")}
                              className="cart-btn btn btn-secondary w-50 ms-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              View Cart
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="accountpiecesDesk"
              onClick={() =>
                window.open(`https://seller.ewshopping.com`, "_blank")
              }
            >
              <span className="accountpiecesDesktext">Sell on EWShopping</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mainshirtscontainer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="shirtscontainer">
            <FaArrowLeft size={22} onClick={() => navigate("/")} />
            <div style={{ marginLeft: "15px" }}>
              <div
                className="shirtstext"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {params.category}
              </div>
              <div
                className="itemstext"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {filtered_products.length} items
              </div>
            </div>
          </div>
          <div className="iconmaincontainer">
            <span>
              <CiSearch
                className="cisearch"
                onClick={() => navigate("/searchproductpage")}
              />
            </span>

            <span onClick={() => navigate("/cart")}>
              <BsHandbag
                className="bshandbag"
                style={{ position: "relative" }}
              />
              <span
                style={{
                  position: "absolute",
                  backgroundColor: "black",
                  color: "#fff",
                  top: "5px",
                  right: "20px",
                  padding: "0px 5px",
                  borderRadius: "70%",
                }}
              >
                {CartItems.length}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div id="page-content" style={{ marginTop: "85px" }}>
        <SliderBrand brand={params.brand} category={params.category} />
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
              <div className="toolbar toolbar-wrapper shop-toolbar mt-3">
                <div className="row align-items-center"></div>
              </div>

              {product_loading === true ? (
                <>
                  <div className="example">
                    <Spin />
                  </div>
                </>
              ) : (
                <>
                  {filtered_products.length > 0 ? (
                    <>
                      <div className="grid-products grid-view-items">
                        <div className="row col-row product-options row-cols-lg-5 row-cols-md-3 row-cols-sm-3 row-cols-2">
                          {filtered_products &&
                            filtered_products.map((product, index) => (
                              <SingleProduct key={index} product={product} />
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="no_product_container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NoProductFound />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footermaincontainer">
        <div>
          <div className="footermaincontainer1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleShow}
            >
              <BiSort className="bisort" />
              <div className="sorttext">SORT</div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="bottom"
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <Offcanvas.Header closeButton style={{ padding: "20px 20px" }}>
                <Offcanvas.Title
                  style={{ fontWeight: "600", fontSize: "16px", margin: "0px" }}
                >
                  Sort By:
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div style={{ padding: "5px" }}>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("High to Low");
                      setShow(false);
                    }}
                  >
                    <GoSortAsc size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: High to Low
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("Low to High");
                      setShow(false);
                    }}
                  >
                    <PiSortAscendingLight size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: Low to High
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-ascending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortAscending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, A-Z
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-descending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortDescending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, Z-A
                    </h6>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => handleFilterShow()}
            >
              <IoFilter className="iofilter" />
              <div className="sorttext">FILTER</div>
            </div>
            <Offcanvas show={filterShow} onHide={handleFilterClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  style={{ fontSize: "18px", fontWeight: "600", margin: "0px" }}
                >
                  CATEGORIES
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="sidebar-categories scrollspy morelist clearfix">
                  {uniqueBrands &&
                    uniqueBrands.map((tag, index) => (
                      <li key={index} style={{ margin: "5px" }}>
                        <input
                          type="checkbox"
                          id={`tag-${index}`}
                          name="avone"
                          value={tag}
                          checked={selected_brand.includes(tag)}
                          onChange={(e) => {
                            handletagPress(e, tag);
                          }}
                        />
                        <label
                          htmlFor={`tag-${index}`}
                          style={{ marginLeft: "5px", fontSize: "16px" }}
                        >
                          <span />
                          {tag}
                        </label>
                      </li>
                    ))}
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default BrandRelatedProduct;
