import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";
import { Affix, Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";
import NoProductFound from "../../component/NoProductFound";
import { MdOutlineCancel } from "react-icons/md";
import { set_checkout_authentication_status } from "../../redux/athentication/Athentication";
import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { BiSort } from "react-icons/bi";
import { getCartTotal } from "../../redux/cart/CartSlice";
import Hadear from "../../component/layout/Hadear";
import { signout } from "../../redux/athentication/Athentication";
import { AiOutlineSortDescending } from "react-icons/ai";
import { AiOutlineSortAscending } from "react-icons/ai";
import { GoSortAsc } from "react-icons/go";
import Offcanvas from "react-bootstrap/Offcanvas";
import { PiSortAscendingLight } from "react-icons/pi";
import NewCategory from "./NewCategory";
import ExploreOur from "./ExploreOur";
import BestAcnePage from "./BestAcnePage";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsShop } from "react-icons/bs";
import HomeBanner from "../home/HomeBanner";
import { FaShoppingBag } from "react-icons/fa";
import PopularNewComponent from "../home/PopularNewComponent";
import { FaSellcast } from "react-icons/fa";
import { getcategoriesBrand, getPriorityBrand } from "../../redux/brand/brandSlice";

const Category = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef();
  const ref = useRef();

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);
  const { isAuth } = useSelector((state) => state.Athentication);
  const { categoryShow, categorytotal } = useSelector(
    (store) => store.category
  );
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const { productTotal, productTotalLoading, isProductTotalAvailable } =
    useSelector((store) => store.product);

  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [catBanner, setCatBanner] = useState("");
  const [current_category, setcurrent_category] = useState("");
  const [category_products, setcategory_products] = useState([]);
  const [tag_loading, settag_Loading] = useState(true);
  const [product_loading, setproduct_Loading] = useState(true);
  const [filtered_products, setfiltered_products] = useState([]);
  const [categoryBrand, setCategoryBrand] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const [gotLimitProduct, setGotLimitProduct] = useState(false);
  const [gotIncreasedLimitProduct, setGotIncreasedLimitProduct] =
    useState(false);
  const [subCatByCat, setSubCatByCat] = useState([]);
  const [catbybrands, setcatbybrands] = useState([]);

  useEffect(() => {
    if (params.category) {
      const findCat = categorytotal.find(
        (data) => data.slugUrl === params.category
      );
      setCatBanner(findCat?.catBannerImage);

      const getBrands = async () => {
        const brand_fetch = await dispatch(getPriorityBrand(params.category));
        if (brand_fetch.payload.success) {
          const get_data = brand_fetch.payload.brands;
          setcatbybrands(get_data);
        }
      };
      getBrands();
    }
  }, [categorytotal, params.category]);

  useEffect(() => {
    const getproducts = async () => {
      const url = `${Baseurl}/api/v1/subcategory/subcatbycaturl/${params.category}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        setSubCatByCat(resp.data.subcategory);
      }
    };
    getproducts();
  }, [params.category]);
  useEffect(() => {
    const brandByCat = async () => {
      const url = `${Baseurl}/api/v1/brand/brandbycat/${params.category}`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        // console.log(resp.data.brands, "resp.data.brands");
        setCategoryBrand(resp.data.brands);
      }
    };
    brandByCat();
  }, [params.category]);
  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);

  useEffect(() => {
    if (params.category) {
      if (productTotal.length === 0) {
        const findCat = categorytotal.find(
          (data) => data.slugUrl === params.category
        );
        // console.log(findCat, "findCat");
        setCatBanner(findCat?.catBannerImage);
        if (findCat || findCat !== undefined) {
          const getCategory = async () => {
            setcurrent_category(findCat);
            const product_url = `${Baseurl2}/api/v1/product/all-products-by-category-limit/${findCat.name}`;
            const product_Respo = await axios.get(product_url);
            if (product_Respo.data.success) {
              const product_data = product_Respo.data.products;
              setcategory_products(product_data);
              setfiltered_products(product_data);
              setproduct_Loading(false);
              setTimeout(() => {
                setGotLimitProduct(true);
              }, 3000);
            }
          };
          getCategory();
        } else {
          const getCategory = async () => {
            const url = `${Baseurl}/api/v1/category/slugurl/${params.category}`;
            const categoryRespo = await axios.get(url);
            if (categoryRespo.data.success) {
              const category_data = categoryRespo.data.category;
              setcurrent_category(category_data);
              const product_url = `${Baseurl2}/api/v1/product/all-products-by-category-limit/${category_data.name}`;
              const product_Respo = await axios.get(product_url);
              if (product_Respo.data.success) {
                const product_data = product_Respo.data.products;
                setcategory_products(product_data);
                setfiltered_products(product_data);
                setproduct_Loading(false);
                setTimeout(() => {
                  setGotLimitProduct(true);
                }, 3000);
              }
            }
          };
          getCategory();
        }
      } else {
        const findCat = categorytotal.find(
          (data) => data.slugUrl === params.category
        );
        if (findCat || findCat !== undefined) {
          setcurrent_category(findCat);
          const filterProduct = productTotal.filter(
            (data) => data.category === findCat.name
          );
          setcategory_products(filterProduct);
          setfiltered_products(filterProduct);
          setproduct_Loading(false);
          setallproductLoading(false);
          setGotLimitProduct(false);
        } else {
          const get_sub_Category = async () => {
            const url = `${Baseurl}/api/v1/category/slugurl/${params.category}`;
            const categoryRespo = await axios.get(url);

            if (categoryRespo.data.success) {
              const category_data = categoryRespo.data.category;
              setcurrent_category(category_data);
              const filterProduct = productTotal.filter(
                (data) => data.category === category_data.name
              );
              setcategory_products(filterProduct);
              setfiltered_products(filterProduct);
              setproduct_Loading(false);
              setallproductLoading(false);
              setGotLimitProduct(false);
            }
          };
          get_sub_Category();
        }
      }
    }
  }, [params.category]);

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      // setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchchange();
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
      setSearch("");
    } else {
      alert("Please type something...");
    }
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowFilterMenu(false);
    }
  };

  useEffect(() => {
    if (showFilterMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterMenu]);

  useEffect(() => {
    if (gotLimitProduct === true) {
      const getProductAll = async () => {
        const product_url = `${Baseurl2}/api/v1/product/all-products-by-category-limitIncrease/${current_category.name}`;
        const product_Respo = await axios.get(product_url);
        if (product_Respo.data.success) {
          const product_data = product_Respo.data.products;
          setcategory_products(product_data);
          setfiltered_products(product_data);
          setallproductLoading(false);
        }
      };
      getProductAll();
    }
  }, [gotLimitProduct]);
  const catSearchClick = (category) => {
    if (search !== "") {
      navigate(`/search/${category.superCategory}/${category.slugUrl}`);
      setShowResults(false);
      setSearch("");
    }
  };
  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };

  // console.log(category_products, "category_products");

  return (
    <>
      {product_loading === true ? (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            // className="backimgloading"
          >
            <Spin />
          </div>
        </>
      ) : (
        <>
          <div>
            <div></div>
            <div
              className="desktopHeader"
              style={{ backgroundColor: "#2F415D" }}
            >
              <div className="leftHeaderDesktop">
                <div>
                  <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                    <Link className="logoImg" to="/">
                      <img
                        src="/assets/images/logonew14.png"
                        alt="EwShopping"
                        title="EwShopping"
                        className="imglogo"
                      />
                    </Link>
                  </div>
                </div>
                {mainHomeSliderTotal &&
                  mainHomeSliderTotal.map((indCat) => (
                    <Link to={`/category/${indCat.slugUrl}`}>
                      <h6 className="categorynametext">
                        {indCat.name.toUpperCase()}
                      </h6>
                    </Link>
                  ))}
              </div>
              <div className="rightHeaderDesktop">
                <div
                  className="inputDivisionDesktop"
                  onSubmit={(e) => searchsubmit(e)}
                >
                  <span>
                    <CiSearch
                      style={{ fontSize: "1.5rem" }}
                      type="submit"
                      onClick={serchclick}
                    />
                  </span>
                  <input
                    placeholder="search for products...."
                    type="search"
                    value={search}
                    onChange={searchchange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyDown}
                  />
                </div>
                {showResults && categorySearchResult.length > 0 && (
                  <>
                    <div className="serchsugist">
                      {categorySearchResult.length > 0 && (
                        <ul className="">
                          {categorySearchResult &&
                            categorySearchResult.map((category, index) => (
                              <li className="item" key={index}>
                                <div
                                  className="mini-list-item d-flex align-items-center w-100 clearfix"
                                  onClick={() => catSearchClick(category)}
                                >
                                  <div className="mini-image text-center">
                                    <Link className="item-link">
                                      <img
                                        className="blur-up lazyload"
                                        data-src={category.desktopImage}
                                        src={category.desktopImage}
                                        alt={category.name}
                                        title="product"
                                        width={120}
                                        height={170}
                                      />
                                    </Link>
                                  </div>
                                  <div className="ms-3 details text-left">
                                    <div className="product-name">
                                      <Link className="item-title">
                                        {category.name}
                                      </Link>
                                    </div>

                                    <Link>
                                      <div className="product-review d-flex align-items-center justify-content-start">
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            color: "#2f415d",
                                          }}
                                        >
                                          in {category.superCategory}
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      )}
                      {subcatSearchResult.length > 0 && (
                        <ul>
                          {subcatSearchResult &&
                            subcatSearchResult.map((sub_category, index) => (
                              <li className="item" key={index}>
                                <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                  <div className="mini-image text-center">
                                    <Link
                                      className="item-link"
                                      to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                      onClick={() => setShowResults(false)}
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src={sub_category.desktopImage}
                                        src={sub_category.desktopImage}
                                        alt={sub_category.name}
                                        title="product"
                                        width={120}
                                        height={170}
                                      />
                                    </Link>
                                  </div>
                                  <div className="ms-3 details text-left">
                                    <div className="product-name">
                                      <Link
                                        className="item-title"
                                        to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                        onClick={() => setShowResults(false)}
                                      >
                                        {sub_category.name}
                                      </Link>
                                    </div>

                                    <div className="product-review d-flex align-items-center justify-content-start">
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          color: "#2f415d",
                                        }}
                                      >
                                        in {sub_category.superCategory}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      )}
                      {categoryTagSearchResult.length > 0 && (
                        <ul>
                          {categoryTagSearchResult &&
                            categoryTagSearchResult.map(
                              (category_tag, index) => (
                                <li className="item" key={index}>
                                  <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                    <div className="mini-image text-center">
                                      <Link
                                        className="item-link"
                                        to={`/tag-search/${category_tag.slugUrl}`}
                                        onClick={() => setShowResults(false)}
                                      >
                                        <img
                                          className="blur-up lazyload"
                                          data-src={category_tag.desktopImage}
                                          src={category_tag.desktopImage}
                                          alt={category_tag.name}
                                          title="product"
                                          width={120}
                                          height={170}
                                        />
                                      </Link>
                                    </div>
                                    <div className="ms-3 details text-left">
                                      <div className="product-name">
                                        <Link
                                          className="item-title"
                                          to={`/tag-search/${category_tag.slugUrl}`}
                                          onClick={() => setShowResults(false)}
                                        >
                                          {category_tag.name}
                                        </Link>
                                      </div>

                                      <div className="product-review d-flex align-items-center justify-content-start">
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            color: "#2f415d",
                                          }}
                                        >
                                          in {category_tag.SubCategory}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                        </ul>
                      )}
                    </div>
                  </>
                )}

                <div className="accountpiecesDesk account-parent iconset">
                  {/* <LuUser className="accountpiecesDeskicon  anm-user-al" />
              <span className="accountpiecesDesktext anm anm-user-al">Profile</span> */}
                  <div className="account-link" title="Account">
                    <i className="hdr-icon icon anm anm-user-al" />
                  </div>
                  <div id="accountBox">
                    <div className="customer-links">
                      <ul className="m-0">
                        {isAuth === true ? (
                          <>
                            <li>
                              <Link to="/my-account">
                                <i className="icon anm anm-user-cil" />
                                My Account
                              </Link>
                            </li>
                            <li onClick={() => signoutClick()}>
                              <i className="icon anm anm-sign-out-al" />
                              Sign out
                            </li>
                          </>
                        ) : (
                          <>
                            <li
                              onClick={() => {
                                dispatch(set_checkout_authentication_status(0));
                                navigate("/login");
                              }}
                            >
                              <Link to="/login">
                                <i className="icon anm anm-user-cil" />
                                Sign In
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <MdOutlineShoppingBag className="accountpiecesDeskicon" />
              <span className="accountpiecesDesktext">Bag</span> */}
                  <div
                    className="accountpiecesDesk header-cart iconset"
                    title="Cart"
                  >
                    <Link
                      className="header-cart btn-minicart clr-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#minicart-drawer"
                    >
                      <i className="hdr-icon icon anm anm-cart-l" />
                      <span className="cart-count">{CartItems.length}</span>
                    </Link>

                    <div
                      id="minicart-drawer"
                      className="minicart-right-drawer offcanvas offcanvas-end"
                      tabIndex={-1}
                    >
                      {CartItems.length === 0 ? (
                        <>
                          <div
                            id="cartEmpty"
                            className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                          >
                            <div className="minicart-header d-flex-center justify-content-between w-100">
                              <h4 className="fs-6">
                                Your cart ({CartItems.length} Items)
                              </h4>
                              <button
                                className="close-cart border-0"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                <i
                                  className="icon anm anm-times-r"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Close"
                                />
                              </button>
                            </div>
                            <div className="cartEmpty-content mt-4">
                              <i className="icon anm anm-cart-l fs-1 text-muted" />
                              <p className="my-3">No Products in the Cart</p>
                              <span
                                className="btn btn-primary cart-btn"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                onClick={() => navigate("/")}
                              >
                                Continue shopping
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div id="cart-drawer" className="block block-cart">
                            <div className="minicart-header">
                              <button
                                className="close-cart border-0"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                <i
                                  className="icon anm anm-times-r"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Close"
                                />
                              </button>
                              <h4 className="fs-6">
                                Your cart ({CartItems.length} Items)
                              </h4>
                            </div>
                            <div className="minicart-content">
                              <ul className="m-0 clearfix">
                                {CartItems &&
                                  CartItems.map((item, index) => (
                                    <li
                                      className="item d-flex justify-content-center align-items-center"
                                      key={index}
                                    >
                                      <span className="product-image rounded-3">
                                        <img
                                          className="blur-up lazyload"
                                          data-src={item.thumbnail}
                                          src={item.thumbnail}
                                          alt="product"
                                          title="Product"
                                          width={120}
                                          height={170}
                                        />
                                      </span>
                                      <div className="product-details">
                                        <span
                                          className="product-title limited-paragraph"
                                          onClick={() => {
                                            if ("slugurl" in item) {
                                              navigate(
                                                `/product-info/${item.slugurl}`
                                              );
                                            }
                                          }}
                                          data-bs-dismiss="offcanvas"
                                          aria-label="Close"
                                        >
                                          {item.ProductName}
                                        </span>
                                        <div className="variant-cart my-2 limited-paragraph">
                                          {item.packSizeOptionName} /{" "}
                                          {item.packSizeName}
                                        </div>
                                        <div className="priceRow">
                                          <div className="product-price">
                                            <span className="price">
                                              {" "}
                                              ₹{item.Price}
                                            </span>{" "}
                                            x{" "}
                                            <span className="price">
                                              {" "}
                                              {item.cart_Quentity}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div className="minicart-bottom">
                              <div className="shipinfo">
                                {amountToGetfeeDelivery !== 0 && (
                                  <div className="progress mb-2">
                                    <div
                                      className="progress-bar progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      aria-valuenow={70}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                      style={{
                                        width: `${amountToGetfeeDeliveryPercentage}%`,
                                      }}
                                    >
                                      {amountToGetfeeDeliveryPercentage}%
                                    </div>
                                  </div>
                                )}

                                {amountToGetfeeDeliveryPercentage === 0 ? (
                                  <>
                                    <div className="freeShipMsg">
                                      <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                      Only{" "}
                                      <span
                                        className="money"
                                        data-currency-usd=" ₹199.00"
                                        data-currency="USD"
                                      >
                                        {" "}
                                        ₹{amountToGetfeeDelivery}
                                      </span>{" "}
                                      away from
                                      <b>Free Shipping</b>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="freeShipMsg d-block">
                                      <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                      Congrats! You are eligible for
                                      <b>Free Shipping</b>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="subtotal clearfix my-3">
                                <div className="totalInfo clearfix mb-1 d-block">
                                  <span>Total:</span>
                                  <span className="item product-price">
                                    {" "}
                                    ₹{TotalPrice}
                                  </span>
                                </div>
                                <div className="totalInfo clearfix mb-1 d-block">
                                  <span>Handlling Fee:</span>
                                  <span className="item product-price">
                                    {" "}
                                    ₹{HandllingFee}
                                  </span>
                                </div>
                                <div className="totalInfo clearfix">
                                  <span>Netpayable:</span>
                                  <span className="item product-price">
                                    {" "}
                                    ₹{TotalAmount}
                                  </span>
                                </div>
                              </div>

                              <div className="minicart-action d-flex mt-3">
                                <span
                                  onClick={() => navigate("/check-out")}
                                  className="proceed-to-checkout btn btn-primary w-50 me-1"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  Check Out
                                </span>
                                <span
                                  onClick={() => navigate("/cart")}
                                  className="cart-btn btn btn-secondary w-50 ms-1"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  View Cart
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="accountpiecesDesk"
                  onClick={() =>
                    window.open(`https://seller.ewshopping.com`, "_blank")
                  }
                >
                  <span className="accountpiecesDesktext">
                    Sell on EWShopping
                  </span>
                </div>
              </div>
            </div>

            <section className="slideshow slideshow-wrapper deskmargin catbannerdesk">
              <Link>
                <div className="home-slideshow slick-arrow-dots">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="slide">
                        <div className="slideshow-wrap">
                          <picture>
                            <source
                              media="(max-width:767px)"
                              srcSet={catBanner}
                              width={1150}
                              height={800}
                            />
                            <img
                              className="blur-up lazyload homebannerimg catbannerdesk"
                              srcSet={catBanner}
                              alt="slideshow"
                              title
                              width={1920}
                              height={795}
                            />
                          </picture>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Link>
            </section>
            {/* <HomeBanner /> */}
            <NewCategory products={catbybrands} category={params.category} />
            {subCatByCat.length > 0 ? (
              <PopularNewComponent subCatByCat={subCatByCat} />
            ) : (
              <></>
            )}
            <ExploreOur products={filtered_products} />
          </div>
          <div className="container">
            <div id="page-content">
              {product_loading === true ? (
                <>
                  <div className="example">
                    <Spin />
                  </div>
                </>
              ) : (
                <>
                  {filtered_products.length > 0 ? (
                    <>
                      <div
                        className="heading"
                       
                      >
                        <h2
                          style={{
                            fontSize: "19px",
                            fontWeight: "700",
                            fontFamily: "Edu VIC WA NT Beginner,cursive",
                            color: "#315999",
                              }}
                              className="produbycont"
                        >
                          PRODUCT BY CATEGORY
                        </h2>
                      </div>
                      <div className="grid-products grid-view-items">
                        <div
                          className="row col-row product-options row-cols-lg-5 row-cols-md-3 row-cols-sm-3 row-cols-2"
                          style={{ marginTop: "10px" }}
                        >
                          {filtered_products &&
                            filtered_products
                              .slice(0, 30)
                              .map((product, index) => (
                                <SingleProduct key={index} product={product} />
                              ))}
                          {product_loading === false &&
                            allproductLoading === true && (
                              <div className="example_new">
                                <Spin />
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <NoProductFound />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="mainshirtscontainer">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="shirtscontainer">
                <FaArrowLeft size={22} onClick={() => navigate("/")} />
                <div style={{ marginLeft: "15px" }}>
                  <div
                    className="shirtstext"
                    style={{ fontWeight: "600", fontSize: "13px" }}
                  >
                    {params.category}
                  </div>
                  <div
                    className="itemstext"
                    style={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {filtered_products.length} items
                  </div>
                </div>
              </div>
              <div className="iconmaincontainer">
                <span>
                  {" "}
                  <CiSearch
                    className="cisearch"
                    onClick={() => navigate("/searchproductpage")}
                  />
                </span>

                <span onClick={() => navigate("/cart")}>
                  <BsHandbag
                    className="bshandbag"
                    style={{ position: "relative" }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      backgroundColor: "black",
                      color: "#fff",
                      top: "5px",
                      right: "20px",
                      padding: "0px 5px",
                      borderRadius: "70%",
                    }}
                  >
                    {CartItems.length}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="menubar-mobile d-flex align-items-center justify-content-between d-lg-none">
            <div className="menubar-shop menubar-item">
              <Link to="/">
                <div className="shop_img">
                  <img src="/assets/images/mobile_logo.png" alt="" />
                </div>
                {/* <span className="menubar-label">Shop</span> */}
              </Link>
            </div>
            {isAuth === true ? (
              <>
                <div className="menubar-account menubar-item">
                  <Link to="/my-account">
                    <i className="menubar-icon icon anm anm-user-al" />
                    <span className="menubar-label">Account</span>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="menubar-account menubar-item">
                  <Link to="/login">
                    <i className="menubar-icon icon anm anm-user-al" />
                    <span className="menubar-label">Account</span>
                  </Link>
                </div>
              </>
            )}
            <div className="menubar-shop menubar-item">
              <Link to="/mobcategory">
                <i className="menubar-icon anm anm-th-large-l" />
                <span className="menubar-label">Categories</span>
              </Link>
            </div>
            {/* <div className="menubar-search menubar-item">
              <Link to="/">
                <span className="menubar-icon anm anm-home-l" />
                <span className="menubar-label">Home</span>
              </Link>
            </div> */}
            {/* <div className="menubar-wish menubar-item">
          <Link to="/Wishlist">
            <span className="span-count position-relative text-center">
              <i className="menubar-icon icon anm anm-heart-l" />
              <span className="wishlist-count counter menubar-count">0</span>
            </span>
            <span className="menubar-label">Wishlist</span>
          </Link>
        </div> */}
            <div className="menubar-cart menubar-item">
              <Link
                to="/cart"
                className="btn-minicart"
                // onClick={() => navigate("/cart")}
              >
                <span className="span-count position-relative text-center">
                  <i className="menubar-icon icon anm anm-cart-l" />
                  <span className="cart-count counter menubar-count">
                    {CartItems.length}
                  </span>
                </span>
                <span className="menubar-label">Cart</span>
              </Link>
            </div>
            <div
              className="menubar-cart menubar-item "
              onClick={() =>
                window.open(`https://seller.ewshopping.com`, "_blank")
              }
            >
              <Link className="btn-minicart">
                <span className="span-count position-relative text-center">
                  <FaSellcast />{" "}
                </span>
                <span className="menubar-label">Sell on Shopping</span>
              </Link>
            </div>
          </div>
        </>
      )}
      <div style={{ marginBottom: "60px" }}></div>
    </>
  );
};

export default Category;
